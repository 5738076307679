html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Change height to min-height */
  /* background-color: white; */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.landing-layout {
  flex-direction: column;
}

.connect-button-container {
  display: flex;
  align-items: center;
}

main {
  flex: 1; /* Change flex-grow to flex */
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  width: 90%;
  padding: 0px 20px; /* Add vertical padding */
}

@media (max-width: 600px) {
  .sample-message-button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

.logo {
  display: flex;
  align-items: center;
  /* font-weight: bold; */
  font-size: 1.6rem;
}

.logo-icon {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-right: 12px; /* Space between icon and text */
}


.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.landing-content {
  /* max-width: 800px; */
  width: 100%;
  text-align: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.x-logo {
  height: 20px; /* Adjust as needed */
  margin-right: 10px;
}